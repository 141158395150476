import { cls, whisper } from '@/utils'
import Image from '@/components/image'
import { CSSProperties, HTMLAttributes, useMemo } from 'react'

export interface ShadowImageCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  maskColor?: string
  variant?: 'primary' | 'default'
  maskDirection?: 'left' | 'bottom'
  src: string
  alt: string
}

export const tailwindSafeList = ['from-[#5F392E]', 'from-[#AD6393]', 'from-[#88837B]']

export default function ShadowImageCard({
  className,
  maskDirection,
  maskColor = 'transparent',
  src,
  alt,
  ...props
}: ShadowImageCardProps) {
  const maskStyle: CSSProperties = useMemo(() => {
    return {
      '--mask-from': maskColor,
      '--mask-to': 'transparent',
    } as any
  }, [maskColor])

  return (
    <div {...props} className={cls('z-0 absolute inset-0', className)} aria-label='shadow image card'>
      <div
        className={cls(
          'z-10 absolute inset-0 from-[36.54%] to-[68.85%] bg-gradient-to-ri from-mask-from via-mask-via to-mask-to',
        )}
        aria-label='mask'
        style={maskStyle}
      />
      <div className='z-0 absolute w-[70%] inset-y-0 right-0 bg-black/80' aria-label='image'>
        <Image src={src} alt={alt} className='w-full h-full object-cover object-center' />
      </div>
    </div>
  )
}
